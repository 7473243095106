
import moment from "moment";
import {
  Component, Vue, Prop, Ref,
} from "vue-property-decorator";
import { Team } from "@/model/team.model";

@Component({
  components: {
    PutTeamOnHoldDialog: () => import("@/components/team-page/PutTeamOnHoldDialog.vue"),
    ConfirmationDialog: () => import("@/components/dialogs/ConfirmationDialog.vue"),
  },
})
export default class TeamHoldStateComponent extends Vue {
  displayHoldExpiresAt = "";

  @Prop()
  pTeam!: Team;

  @Ref()
  putTeamOnHoldDialog: any;

  get teamOnHold() {
    return !!this.pTeam.holdExpiresAt;
  }

  get teamHoldText() {
    return this.teamOnHold ? `On hold for another ${this.displayHoldExpiresAt}` : "Not on hold";
  }

  get teamHoldChipColor() {
    if (this.teamOnHold) return ["blue-grey", "darken-2", "white--text"];

    return ["green", "white--text"];
  }

  get holdIcon() {
    if (this.teamOnHold) {
      // TODO: ACS-549
      // return "mdi-play-circle";
      return "";
    }
    return "mdi-hand-front-right";
  }

  get holdTooltipText() {
    if (this.teamOnHold) {
      return "Unhold the team";
    }

    return "Put on hold";
  }

  holdTeamAction() {
    if (!this.teamOnHold) {
      this.openPutTeamOnHoldDialog();
      // TODO: ACS-549
      // return;
    }
    // TODO: ACS-549
    // this.openUnholdTeamDialog()
  }

  openPutTeamOnHoldDialog() {
    this.putTeamOnHoldDialog.openDialog();
  }

  // TODO: ACS-549 As Manager, Host Admin or SA, I want to be able to unhold a team
  // openUnholdTeamDialog() {
  //   this.$refs.confirmationDialog.openDialog({
  //     header: "",
  //     text: "",
  //     submitText: "Unhold",
  //     submitColor: "green",
  //     onSubmit: async () => {
  //
  //     },
  //   });
  // }

  monitorHoldExpiration() {
    const holdExpiresAt = this.pTeam.holdExpiresAt;

    if (holdExpiresAt) {
      const diff = moment(holdExpiresAt).diff(moment());
      if (diff <= 0) {
        this.$emit("onUpdate");
      } else {
        this.displayHoldExpiresAt = (moment.duration(diff, "milliseconds") as any)
          .format("d [days] h [hours] m [min] s [sec]");
      }
    }
    setTimeout(() => this.monitorHoldExpiration(), 1000);
  }

  mounted() {
    this.monitorHoldExpiration();
  }
}
